import { useEffect, useState } from "react";
import LiveSessionListings from "./LiveSessionListing";
import { ActiveSimulate } from "./ActiveSimulate";
import { ActiveSessionBalance } from "./ActiveSessionBalance";
import { useDispatch, useSelector } from "react-redux";
import { fetchCustomerSummary } from "../../slices/customer/simulate/thunk";
import { UpdateSimulate } from "./UpdateSimulate";

export const LiveSession = ({ customerId, showForm, setShowForm, setButtonInitiateSession,isSimulateView }) => {
    const [loading, setLoading] = useState(false);
    const [updateSession, setUpdateSession] = useState(false);
    const [terminateSessions, setTerminateSessions] = useState(false);
    const [sessionInfo, setSessionInfo] = useState({ sessionId: null, sequenceNumber: null });
    const [currentCustomerId, setCurrentCustomerId] = useState(customerId);

    useEffect(() => {
        if (updateSession) {
            setButtonInitiateSession(false);
        }
    }, [updateSession, setButtonInitiateSession]);


    return (
        <div className="mt-4">
            {updateSession && (
                <UpdateSimulate
                    updateSession={updateSession}
                    setUpdateSession={setUpdateSession}
                    sessionInfo={sessionInfo}
                    terminateSessions={terminateSessions}
                    setTerminateSessions={setTerminateSessions}
                    setButtonInitiateSession={setButtonInitiateSession}
                />
            )}
            <div className={updateSession ? "loader-style disabled" : ""}>
                <div className={isSimulateView?"mt-n5":""}>
                <ActiveSimulate showForm={showForm} setShowForm={setShowForm} /></div>
                <ActiveSessionBalance customerId={currentCustomerId}/>
                <div className="col-12">
                    <LiveSessionListings
                        customerId={customerId}
                        updateSession={updateSession}
                        setUpdateSession={setUpdateSession}
                        sessionInfo={sessionInfo}
                        setSessionInfo={setSessionInfo}
                        terminateSessions={terminateSessions}
                        setTerminateSessions={setTerminateSessions}
                    />
                </div>
            </div>
        </div>
    );
};
